import React from 'react'

import logo from '../assets/images/logo.jpg'

function Footer() {
  return (
    <section id="footer">
      <div className="grid-wrapper">
        <div className="col-12">
          <img width={200} className="logoFooter" src={logo} alt="" />
          <ul className="icons">
            <li>
              <a
                href="http://facebook.com/imobat-328128084704889/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="mailto:contact@imobat.fr"
                className="icon alt fa-envelope"
              >
                <span className="label">Email</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-12 adresse">
          <ul style={{ fontFamily: 'Montserrat-Bold'}}>
            <li>IMOBAT</li>
            <li>ZAC DE LA FORGE</li>
            <li>24 D Rue Jean-Baptiste Godin</li>
            <li>35590 SAINT GILLES</li>
            <li>02.99.64.44.47</li>
            <li>contact@imobat.fr</li>
          </ul>
        </div>
        <div className="col-12">
          <ul className="copyright">
            <li>&copy; Copyright Imobat 2020</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Footer
