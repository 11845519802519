import React from 'react'
import { Link } from 'gatsby'
import Menu from '../assets/images/menu.png'
import Close from '../assets/images/close.png'
import logo from '../assets/images/logo.jpg'

function Nav() {
  const [openMenu, setOpenMenu] = React.useState(false)
  const [scroll, setScroll] = React.useState(1)

  React.useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 300
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })

  const items = [
    { id: 'home', name: 'accueil', link: '/' },
    { id: 'services', name: 'missions', link: '/services' },
    { id: 'projects', name: 'projets', link: '/projets' },
    { id: 'contact', name: 'contact', link: '/contact' },
  ]

  const itemNav = items.map((item) => (
    <Link key={item.id} id={item.id} to={item.link} className="menu-item">
      {item.name}
    </Link>
  ))

  return (
    <>
      <nav className={scroll ? 'navbar' : 'navbar navScrolled'}>
        <img
          className={scroll ? 'hidden' : 'logoNav'}
          src={logo}
          alt="Imobat"
        />
        <div className="group-item">{itemNav}</div>
      </nav>

      <img
        onClick={() => setOpenMenu((on) => !on)}
        className="bm-burger-button"
        src={openMenu ? Close : Menu}
        alt=""
      />

      {openMenu && (
        <>
          <div onClick={() => setOpenMenu(false)} className="background-menu" />
          <nav className="bm-menu">
            <div className="bm-item">{itemNav}</div>
          </nav>
        </>
      )}
    </>
  )
}

export default Nav
