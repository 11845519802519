import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import '../assets/scss/main.scss'

import Footer from '../components/Footer'
import Nav from '../components/Nav'

class Template extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' })
    }, 400)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  render() {
    const { location, children } = this.props
    return (
      <div>
        <Helmet defaultTitle="Imobat" titleTemplate="%s | Imobat">
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Imobat" />

          <link
            rel="canonical"
            href={`https://imobat.fr${location.pathname}`}
          />
          <html lang="fr" />
        </Helmet>
        <Nav />
        {children}
        <Footer />
      </div>
    )
  }
}

export default Template
